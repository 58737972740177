import * as React from 'react';
import { Layout } from 'src/components/layout/layout';
import { ThankYou } from 'src/components/thankyou';

function ThankYouPage() {
  return (
    <Layout>
      <ThankYou />
    </Layout>
  );
}

export default ThankYouPage;
