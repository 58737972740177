import { navigate } from 'gatsby';
import React from 'react';
import { ContentSection } from 'src/components/layout/layout';
import 'twin.macro';
import { PrimaryButton } from '../lib/button';
import { BackLink } from '../lib/navlinks/back';
import { H2 } from '../lib/typography';
import { useLocation } from '@reach/router';

const feedbackDictionary = {
  contact: {
    title: 'Message sent!',
    image: '/msg-sent.svg',
    description: 'Thank you for your message, our team will be in touch soon!',
    buttonText: 'Back to homepage',
    buttonLink: '/',
  },
  'submit-bip': {
    title: 'Thank you for submitting your BIP',
    image: '/msg-sent.svg',
    description: 'Our team will review your report and respond back, usually within 14 days.',
    buttonText: 'Back to homepage',
    buttonLink: '/',
  },
  'submit-report': {
    title: 'Thank you for your submission!',
    image: '/msg-sent.svg',
    description: 'Our team will review your report and respond back, usually within 14 days.',
    buttonText: 'Back to bip bounties',
    buttonLink: '/bounties',
  },
};

const Feedback = ({
  data: { title, image, description, buttonText, buttonLink },
}: {
  data: typeof feedbackDictionary['contact'];
}) => {
  return (
    <div className="w-full text-align[center]">
      <H2 className="text-center pb-6">{title}</H2>
      <div className="flex justify-center mb-8">
        <img src={image} />
      </div>
      <p className="pb-6">{description}</p>
      <div className="m-5">
        <PrimaryButton className="w-72" onClick={() => navigate(buttonLink)}>
          {buttonText}
        </PrimaryButton>
      </div>
    </div>
  );
};

export const ThankYou = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get('type');

  const feedbackData = feedbackDictionary[type];

  if (!feedbackData) return null;

  return (
    <ContentSection>
      <BackLink />
      <Feedback data={feedbackData} />
    </ContentSection>
  );
};
